<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Portabilidad</h4>
            <div class="small text-muted">Carga y descarga de plantillas</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>   
        <b-col md="6">
          <b-card bg-variant="dark" text-variant="white" title="Carga Plantilla" class="portability-message-custom">
            <b-card-text>
              Cargar una plantilla en formato .ZIP (previamente descargado desde el sistema).<br> 
              Esta plantilla reemplazará todo el sitio web diseñado hasta el momento. <br>
              Todos los contenidos actuales se reemplazará por los cargados en la plantilla.
            </b-card-text>
            <b-button variant="info" @click="openLoadTemplate()">Cargar</b-button>
            <b-link href="javascript:void(0);" class="pull-right portability-blank-template" variant="danger" @click="blankTemplate()">Eliminar Plantilla</b-link>
          </b-card>          
        </b-col>

        <b-col md="6">
          <b-card bg-variant="dark" text-variant="white" title="Descarga Plantilla" class="portability-message-custom">
            <b-card-text>
              Se desacargará un archivo .ZIP con todos los diseños y contenidos cargados en el sitio web actual.
              Este archivo, nos permite mover todo el sitio web de un sistema a otro.            
            </b-card-text>
            <b-button variant="success" @click="downloadTemplate()">Descargar</b-button>
          </b-card>          
        </b-col>
      </b-row>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                                            
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.load.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.load.title}}
        </div>

        <b-row>
          <b-col sm="12">
            <b-form-group label="Plantilla">                
              <b-form-file  v-model="crud.form.file"                             
                            size="sm"
                            :state="Boolean(crud.form.file)"
                            placeholder="Elija un archivo o sueltelo aquí..."
                            drop-placeholder="Suelta el archivo aquí..."
                            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed">
              </b-form-file>                    
            </b-form-group>
          </b-col>            
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.load.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="loadTemplate()">Cargar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.CONTENIDOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudPortabilityTemplate',
          elements: {}
        },      
        crud: {
          form: {
            file: null,                 
          },          
        },        
        modal: {
          load: {
            active: false,
            title: ''
          },
        },                                   
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    methods: {
      openLoadTemplate() {
        this.modal.load.title = "Cargar Template"
        this.modal.load.active = true        
      },      
      loadTemplate() {
        this.$bvModal.msgBoxConfirm('¿Continuar con la carga de la plantilla?', {
          title: 'Cargar Plantilla',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'info',
          okTitle: 'CARGAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();

            var file = new FormData();
            if(this.crud.form.file) {          
              file.append("file", this.crud.form.file);
            }

            var result = serviceAPI.portabilityLoadTemplate(JSON.stringify(this.crud.form), file);

            result.then((response) => {      
              this.modal.load.active = false        
              loader.hide()                            
              this.$awn.success("Plantilla Cargada");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })            
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },
      downloadTemplate() {
        this.$bvModal.msgBoxConfirm('¿Continuar con la descarga de la plantilla?', {
          title: 'Descargar Plantilla',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'success',
          okTitle: 'DESCARGAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.portabilityDownloadTemplate(null);

            result.then((response) => {                         
              loader.hide()                            
              this.$awn.success("Plantilla Descargada");

              var data = response.data              

              window.open(data, '_blank');
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })                                    
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },
      blankTemplate() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la eliminación de la plantilla actual?', {
          title: 'Eliminar Plantilla',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();

            var result = serviceAPI.portabilityBlankTemplate(null);

            result.then((response) => {                      
              loader.hide()                            
              this.$awn.success("Plantilla Eliminada");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })            
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })            
      }
    }
  }
</script>
<style scoped>
  .portability-message-custom .card-text {
    height: 80px !important;    
  }
  .portability-blank-template {
    color: red;
    margin-top: 10px; 
  }
</style>